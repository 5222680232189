<template>
  <div class="container">
    <el-form :model="searchForm" ref="searchForm" label-width="70px" class="searchForm">

      <el-row>
        <el-col :span='6'>
          <el-form-item label="绩效名称">
            <el-input type="text" v-model="searchForm.title" class="default"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span='6'>
          <el-form-item label="状态">
            <selects @handleChange="handleSelect($event,0)" ref="typeRef" :options="type">
            </selects>
          </el-form-item>
        </el-col>
        <el-col :span='6'>
          <el-form-item label="年份">
            <el-date-picker v-model="searchForm.year" type="year" format="yyyy 年"
              value-format="yyyy">
            </el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span='6'>
          <el-form-item label="月份">
            <el-date-picker v-model="searchForm.month" type="month" format=" MM 月"
              value-format="MM">
            </el-date-picker>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row>
        <el-col :span='6' :offset="18">
          <el-button class="formBtn" @click="openNewDefect(id=-1)">新建工作绩效</el-button>
          <el-button class="formBtn btn-reset" @click="resetForm">重置</el-button>
          <el-button class="formBtn" @click="search(type=-1)" :loading="Searching">
            {{Searching ?  '搜索中...':'搜索'}}</el-button>
        </el-col>
      </el-row>

    </el-form>

    <dialogScore @createWorkScore="handleNewScore" ref="createWorkScore"></dialogScore>
    <!-- 查看绩效详情 -->
    <el-dialog title="绩效详情" :visible.sync="scoreDetail" width="800px">
      <el-form label-position="top" label-width="100px" :model="currentDate">
        <el-row :gutter="30">
          <el-col :span="12">
            <el-form-item label="工作绩效">
              <el-input type="textarea" placeholder="工作绩效" rows="8" v-model="currentDate.content"
                maxlength="1024" :disabled="true" show-word-limit>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="学习培训">
              <el-input type="textarea" placeholder="学习培训" rows="8" v-model="currentDate.study"
                maxlength="1024" :disabled="true" show-word-limit>
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="30">
          <el-col :span="12">
            <el-form-item label="工作内不足处">
              <el-input type="textarea" placeholder="工作内不足处" rows="8" v-model="currentDate.lacking"
                maxlength="1024" :disabled="true" show-word-limit>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="绩效备注">
              <el-input type="textarea" placeholder="绩效备注,述求 （非必填）" rows="8"
                v-model="currentDate.mem" maxlength="1024" :disabled="true" show-word-limit>
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="scoreDetail = false">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 项目列表 -->
    <div>
      <el-table ref="plantList" :data=" plantList" stripe v-loading="Searching"
        :row-style="{height:'64px',}" :cell-style="{'text-align':'center'}"
        :header-cell-style="{'border-top':'solid 1px #E3E3E3','background': '#F5F5F5','text-align':'center'}"
        style="width: 100%">
        <el-table-column prop="scoreId" label="绩效编号" width="100" align="center">
        </el-table-column>
        <el-table-column prop="title" label="绩效名称" min-width="130">
        </el-table-column>
        <el-table-column prop="userName" label="用户名" min-width="100">
        </el-table-column>

        <el-table-column prop="content" v-if="isShowOption" label="绩效内容" min-width="130">
          <template slot-scope="scope">
            <el-button type="text" @click="opDetails(scope.row)">详情
            </el-button>
          </template>
        </el-table-column>

        <!-- 隐藏内容 -->
        <el-table-column prop="content" v-if="!isShowOption" label="绩效详情" min-width="120">
          <template slot-scope="scope">
            <p class="longInfo"> {{scope.row.content}}</p>
          </template>
        </el-table-column>
        <el-table-column prop="study" v-if="!isShowOption" label="学习培训" min-width="120">
          <template slot-scope="scope">
            <p class="longInfo"> {{scope.row.study}}</p>
          </template>
        </el-table-column>
        <el-table-column prop="lacking" v-if="!isShowOption" label="工作内不足处" min-width="120">
          <template slot-scope="scope">
            <p class="longInfo"> {{scope.row.lacking}}</p>
          </template>
        </el-table-column>
        <el-table-column prop="mem" v-if="!isShowOption" label="绩效备注" min-width="120">
          <template slot-scope="scope">
            <p class="longInfo"> {{scope.row.mem}}</p>
          </template>
        </el-table-column>

        <el-table-column prop="year" label="对应年度" min-width="100">
          <template slot-scope="scope">
            <p>{{scope.row.year}}年</p>
          </template>
        </el-table-column>
        <el-table-column prop="month" label="对应月份" min-width="100">
          <template slot-scope="scope">
            <p>{{scope.row.month}}月</p>
          </template>
        </el-table-column>
        <el-table-column prop="periodBegin" label="开始时间" min-width="130">
          <template slot-scope="scope">
            <p>{{scope.row.periodBegin | StringDate}}</p>
          </template>
        </el-table-column>
        <el-table-column prop="type" label="绩效类型" min-width="80">
          <template slot-scope="scope">
            <span>
              {{(scope.row.type==0) ? '日' : ''}}
              {{(scope.row.type==1) ? '周' : ''}}
              {{(scope.row.type==2) ? '月度' : ''}}
              {{(scope.row.type==3) ? '年度' : ''}}
            </span>
          </template>
        </el-table-column>
        <el-table-column prop="status" label="状态" min-width="130">
          <template slot-scope="scope">
            <span style="color: #409EFF;">
              {{(scope.row.status==0) ? '未查看' : ''}}
              {{(scope.row.status==1) ? '已读' : ''}}
            </span>
          </template>
        </el-table-column>
        <el-table-column label="操作" v-if="isShowOption" fixed="right" min-width="160">
          <template slot-scope="scope">
            <el-button type="text" @click="openNewDefect(scope.row.scoreId)">编辑</el-button>
            <el-button type="text" @click="openNewDefect(scope.row.scoreId,copy=1)">复制
            </el-button>
            <el-button type="text" @click="deleteApi(scope.row.scoreId)" class="delectBtn">删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div style="text-align: right;margin: 40px 50px;">
      <el-button type="primary" size="medium" @click="exportExcel">
        导出报表
      </el-button>
    </div>

    <!-- 分页器 -->
    <div class="pagination">
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
        :current-page="1" :page-sizes="[5, 10, 15]" :page-size="100"
        layout="total, sizes, prev, pager, next, jumper" :total="dataTotal">
      </el-pagination>
    </div>

  </div>
</template>

<script>
import FileSaver from "file-saver";
import XLSX from "xlsx";

import { SCORE_QUERTLIST, SCORE_DELEBYID, SCORE_ADDEDIT } from "@/request/api";
import { selects, dialogScore } from "./tools";
export default {
  data() {
    return {
      dataTotal: 0,
      Searching: false, //搜索中
      scoreDetail: false, //查看报告详情
      isShowOption: true, //是否显示列表操作栏
      currentDate: {}, //当前选中行的数据
      searchForm: {
        title: "", //绩效名称
        type: "", //绩效类型
        year: "", //对应年度
        month: "", //对应月份

        pageNumber: 1,
        pageSize: 5,
      },
      // 项目名称
      projectName: [],
      //绩效类型
      type: [
        { value: 0, label: "日" },
        { value: 1, label: "周" },
        { value: 2, label: "月度" },
        { value: 3, label: "年度" },
      ],

      plantList: [],
    };
  },
  created() {
    this.search();
  },
  methods: {
    // 重置搜索条件
    resetForm() {
      this.searchForm.title = "";
      this.searchForm.type = "";
      this.searchForm.year = "";
      this.searchForm.month = "";
      let refList = ["typeRef"];
      refList.forEach((element) => {
        this.$refs[element].resetValue();
      });
      this.search();
    },
    // 下拉框选择赋值
    handleSelect(val, type) {
      if (type == 0) {
        this.searchForm.type = val;
      }
    },
    // 查看报告详情
    async opDetails(data) {
      this.currentDate = data;
      this.scoreDetail = true;

      if (!data.status) {
        const { data: res } = await SCORE_ADDEDIT({
          scoreId: data.scoreId,
          status: 1,
        });
        if (res.resCode == 1) {
          this.$notify({ title: "状态修改成功", type: "success" });
          this.search();
        }
      }
    },
    // 打开工作绩效对话框
    openNewDefect(id, copy) {
      this.$refs.createWorkScore.openNewDefect(id, copy);
    },
    // 新建工作绩效
    handleNewScore(val) {
      SCORE_ADDEDIT(val)
        .then(({ data: res }) => {
          if (res.resCode == 1) {
            this.$notify({ title: "操作成功", type: "success" });
          } else {
            this.$notify.error({
              title: "操作失败",
            });
          }
          setTimeout(() => {
            this.search();
            this.$refs.createWorkScore.handleClose();
          }, 400);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    
    // 查询搜索
    search(type) {
      if (type == -1) {
        this.searchForm.pageNumber = 1;
      }
      this.Searching = true;
      const waitsearch = setTimeout(() => {
        this.Searching = false;
      }, 600);
      SCORE_QUERTLIST(this.searchForm)
        .then(({ data: res }) => {
          if (res.resCode == 1) {
            this.dataTotal = res.data.total;
            this.plantList = res.data.list;
          }
          waitsearch;
        })
        .catch((err) => {
          console.log(err);
          waitsearch;
        });
    },
    //删除工作绩效
    deleteApi(scoreId) {
      this.$confirm("此操作将永久删除, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          SCORE_DELEBYID([scoreId])
            .then(({ data: res }) => {
              if (res.resCode == 1) {
                this.$notify.success({
                  title: "成功",
                  message: "删除成功",
                });
                this.search();
              } else {
                this.$notify.error({
                  title: "失败,请重试",
                  message: res.resMsg,
                });
              }
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch(() => {});
    },

    /* 导出excel表格 */
    exportExcel() {
      this.isShowOption = false;
      this.$prompt("请输入报表名称", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        inputValidator: (excelName) => {
          if (!excelName) return "报表名称不能为空";
        },
      })
        .then(({ value: excelName }) => {
          try {
            const $e = this.$refs["plantList"].$el;
            // 如果表格加了fixed属性，则导出的文件会生产两份一样的数据，所以可在这里判断一下
            let $table = $e.querySelector(".el-table__fixed");
            if (!$table) {
              $table = $e;
            }
            // 为了返回单元格原始字符串，设置{ raw: true }
            const wb = XLSX.utils.table_to_book($table, { raw: true });
            const wbout = XLSX.write(wb, {
              bookType: "xlsx",
              bookSST: true,
              type: "array",
            });
            FileSaver.saveAs(
              new Blob([wbout], { type: "application/octet-stream" }),
              `${excelName}.xlsx`
            );
          } catch (e) {
            if (typeof console !== "undefined") console.error(e);
          }
          this.isShowOption = true;
        })
        .catch(() => {
          this.isShowOption = true;
        });
    },
    // 分页器
    handleSizeChange(val) {
      this.searchForm.pageSize = val;
      this.search();
    },
    handleCurrentChange(val) {
      this.searchForm.pageNumber = val;
      this.search();
    },
  },

  components: {
    selects,
    dialogScore,
  },
};
</script>

<style lang="less" scoped>
.default {
  width: 200px;

  /deep/ .el-input__inner {
    height: 36px;
    line-height: 36px;
    border-radius: 0;
  }
}
.searchForm {
  margin-bottom: 25px;
}

// 搜索按钮
.formBtn {
  height: 36px;
  background: #3285ea;
  font-size: 13px;
  font-weight: 400;
  text-align: center;
  color: #ffffff;
  line-height: 36px;
  padding: 0;
  border-radius: 0;
  padding: 0 22px;
}
.btn-reset {
  color: rgba(0, 0, 0, 0.65);
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.15);
}
.delectBtn {
  margin-left: 16px;
}
// 外链描述等内容
.longInfo {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
